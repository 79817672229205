body {
  min-height: 100vh;
  min-height: -webkit-fill-available;
}
html {
  height: -webkit-fill-available;
}
body {
  font-size: 8px;
}
label {
  font-size: 8px;
}
select {
  font-size: 12px;
}
div {
  font-size: 12px;
}
input {
  padding: 5px;
}

.form-control {
  padding: 0px;
  margin-bottom: 10px;
}

input[type="text"] {
  padding-left: 5px;
}

body .react-datepicker__day-name,
body .react-datepicker__day {
  width: 20px;
  height: 20px;
  line-height: 20px;
  font-weight: normal;
}

div.datepicker {
  display: inline;
  margin: 5px;
}

.react-datepicker__time-container {
  width: 100px;
  font-size: 8px;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 100%;
  font-size: 10px;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item {
  height: 25px;
  padding: 5px 10px;
}

.react-datepicker-popper {
  /*changed this so select on AddModal would close when clicking on invisible part - Sept 3 2021 JL - but don't know if this affects anything elsewhere   */
  widthx: 300px;
  z-index: 1500;
}

body .react-datepicker__header {
  border: 0;
  background: #fff;
  font-family: AauxBold;
  font-weight: normal;
  font-style: normal;
}

body .react-datepicker__current-month {
  font-size: 16px;
  font-weight: 500;
  padding: 7px 0;
}

body .react-datepicker__navigation {
}
body .react-datepicker {
  font-size: 8pt;
}

div.recurringSelect {
  max-width: 200px;
  z-index: 99999999;
}

img {
  width: 30px;
  height: 30px;
  padding-right: 10px;
}
div.modal-body {
  padding-left: 50px;
}

inputx[type="checkbox"] {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  cursor: pointer;
  opacity: 0;
}

inputx[type="checkbox"] span {
  position: absolute;
  top: 10px;
  left: 15px;
  width: 25px;
  background: #fff;
  height: 25px;
  border: 1px solid #cccccc;

  border-radius: 2px;
}

table td {
  padding: 10px;
  border: solid 2px;
  vertical-align: text-top;
  text-align: left;
}
table td ul {
  list-style-type: none;
  padding-left: 0;
  text-align: left;
}
table td ul li {
  padding-left: 0;
}

.calendarDatepicker .react-datepicker__day-name,
.calendarDatepicker .react-datepicker__day- {
  widthxx: 40px;
  height: 40px;
  line-height: 20px;
  font-weight: normal;
}
.calendarDatepicker {
  margin-top: 100px;
}

.calendarDatepicker .react-datepicker-popper,
.calendarDatepicker .react-datepicker {
  width: 100%;
}
div.react-datepicker-wrapper,
div.react-datepicker__input-container,
input.react-datepicker-ignore-onclickoutside {
  width: 100%;
  /* width:200px; ? is the 200px used anywher? */
}

div.react-datepicker-wrapper input {
  width: 100%;
}
.nav {
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

div.row {
  font-sizex: 10px;
  borderx: solid yellow 1pt;
}
div.col {
  borderx: dotted green;
  font-sizex: 10px;
}

.modal-right {
  position: absolute;
  right: 40px;
  width: 100%;
}

.rbc-month-row,
.rbc-event-content {
  overflow: visible;
}
.rbc-row-segment {
  position: relative;
}

.rbc-time-content {
}
.rbc-time-view-resources {
  max-width: 85vw;
}
/*todo make this conditional for horses/tack - make flex grow to maz*/
.xrbc-time-view-resources .rbc-time-header-content {
  min-width: 75px;
  max-width: 75px;
}
.xdiv.rbc-time-view.rbc-time-view-resources div.rbc-time-content div.rbc-today.rbc-day-slot.rbc-time-column {
  min-width: 75px;
  max-width: 75px;
}
div.add {
  border: solid #e6e6e6 15px;
  padding-bottom: 200px;
}

.rbc-today {
  background-color: #efeaef3d;
}

.btn-primary {
  color: white;
  background-color: #ff7900;
  border-color: #ff7900;
}

.btn.btn-primary.hover,
.btn.btn-primary:hover,
.btn.btn-primary.active,
.btn.btn-primary:active,
.btn.btn-primary.focus,
.btn.btn-primary:focus,
.btn.btn-primary.dropdown-toggle:focus,
.dropdown-toggle:focus,
.dropdown-toggle:hover,
.dropdown-toggle:active .btn-primary.dropdown-toggle:active,
.show > .btn-primary.dropdown-toggle:active {
  color: white;
  background-color: #66c2d7;
  border-color: #66c2d7;
}

button.btn-close {
  color: white;
  background-color: #66c2d7;
  border-color: #66c2d7;
  min-width: 100px;
}

.btn-save {
  color: white;
  background-color: #ff7900;
  border-color: #ff7900;
}

div.rbc-timeslot-group {
  min-height: 60px;
}

.__react_component_tooltip {
  max-width: 400px;
  color: grey;
  background-color: grey;
  border: solid 1px black;
  opacity: 1;
}

.__react_component_tooltip.type-dark {
  color: black;
  background: white;
  opacity: 1;
}
.__react_component_tooltip.type-light {
  color: blue;
  background: white;
}

f.div.defaultTooltipStyle {
  color: white;
  background-color: #ff7900;
  border-color: #ff7900;
  opacity: 1;
}

f.__react_component_tooltip.type-dark {
  color: white;
  background-color: #ff7900;
  border-color: #ff7900;
  opacity: 1;
}

f.__react_component_tooltip.type-light {
  color: black;
  background-color: white;
  border: solid 1px black;
  opacity: 1;
}

.customTip {
  background-color: red;
}

.__react_component_tooltip {
  max-width: 200px;
  color: grey;
  background-color: grey;
  border: solid 1px black;
  opacity: 1;
}

div.modal-header {
  background-color: "#66c2d7";
  color: "#FFFFFF";
  border-radius: "2px";
}

div.repeatDatepicker .react-datepicker__input-container input {
  max-height: 28px;
  min-height: 28px;
  border: none;
  margin: 0 10px 0 10px;
}

button.x {
  background-color: white;
  margin: 5px 0;
  border-radius: 10%;
}

.error-message {
  color: #ff6565;
  padding: 0.5em 0.2em;
  height: 3em;
  position: absolute;
  font-size: 0.8em;
}

.modal-90w {
  width: 90%;
  max-width: none !important;
}
.modal-100w {
  width: 100%;
  height: 100%;
  max-height: none !important;

  max-width: none !important;
}

.react-datepicker__day--highlighted {
  background-color: #ff7900;
  color: "white";
}

.react-datepicker {
  font-size: 0.6em;
}
.react-datepicker__header {
  padding-top: 0.8em;
}
.react-datepicker__month {
  margin: 0.4em 1em;
}
.react-datepicker__day-name,
.react-datepicker__day {
  width: 1em;
  line-height: 1em;
  margin: 0.166em;
}
.react-datepicker__current-month {
  font-size: 0.8em;
}
.react-datepicker__navigation {
  top: 1em;
  line-height: 0.7em;
  border: 0.45em solid transparent;
}
.react-datepicker__navigation--previous {
  border-right-color: #ccc;
  left: 1em;
}
.react-datepicker__navigation--next {
  border-left-color: #ccc;
  right: 1em;
}
