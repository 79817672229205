div.leftButton button, a.dropdown-item button {
  background-color: #ff7900;
  padding:2 2;
  width:100%;
  text-align: middle;
  color:white;
  min-height:35px;
  min-width: 70px;
}

/*div.rightButton button, button.rightButton{
  background-color: #ff7900;
  padding:2 2;
  text-align: middle;
  color:white;
  min-height:35px;
  min-width: 100px;
  width:100%;
  border: solid 1px white;

}

div.dropdown .list-group-item{
margin:0;
background: white;
border: solid 1px white;
padding:0;

}
*/

div.leftButton .list-group-item{
  padding:0.5 ;
  border: none;

}



div.attendees .list-group-item{
    padding:10  5;
  }

  div.editMe {
    	color: #818182;
    	background-color: #fdfdfe;
      padding: 0;
      padding-top:10px;
    	border: 1px solid rgba(0,0,0,.125);
      text-align: center;
      min-Height:100px;
      margin:auto;
      position:relative;
  }
  div.editMe div.row{  text-align: center;}

  div.blocklabel{margin-bottom:0; text-align:center}
div.modal-body{ padding-right:50px !important;}
div.customTip {background-color: red;}

  @media only screen and (max-width : 767px) {
    div.editMe {
        min-height: 50px !important;
    }
}
