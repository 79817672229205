label {
  margin-bottom: 1px
}

.customTheme {
 color: #ff6e00 !important;
 background-color: #ff7900 !important;

 border-top-color: #ff7900 !important;
 border-top-style: solid !important;
 border-top-width: 6px !important;

}

div.blocklabel {
padding: 5px 20px;
font-size: 12px;
background-color: #66c2d7;
color: white;
margin-top:  30px;


}

div.add {
border: solid #e6e6e6 15px;

}

div.h4 {margin:10px; }

div.titleRow { background-color:#66c2d7  ; color:white; border-bottom: solid 1pt lightgrey ; text-align: center}
